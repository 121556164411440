import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

//component file
import Commoninformation from '../Pagehome/Commoninformation';
import Pagination from '../../components/Pagination';

const categories = [
    { id: 1, name: 'Politics' },
    { id: 2, name: 'Technology' },
    { id: 3, name: 'Sports' },
    { id: 4, name: 'Health' },
    { id: 5, name: 'Entertainment' },
    { id: 6, name: 'Business' }
];

const Detail = () => {

    const [isPreviewDropdownOpen, setIsPreviewDropdownOpen] = useState(false);
    const [isNextDropdownOpen, setIsNextDropdownOpen] = useState(false);

    const togglePreviewDropdown = () => {
        setIsPreviewDropdownOpen(!isPreviewDropdownOpen);
    };

    const toggleNextDropdown = () => {
        setIsNextDropdownOpen(!isNextDropdownOpen);
    };

    const handlePreviewMouseEnter = () => {
        setIsPreviewDropdownOpen(true);
    };

    const handlePreviewMouseLeave = () => {
        setIsPreviewDropdownOpen(false);
    };

    const handleNextMouseEnter = () => {
        setIsNextDropdownOpen(true);
    };

    const handleNextMouseLeave = () => {
        setIsNextDropdownOpen(false);
    };

    return (

        <>
            <Commoninformation />
            <div className='detail-page padding-50'>
                <div className='container-lg'>
                    <div className='row row-gap-4'>
                        <div className='col-md-7 col-lg-7 col-xl-8'>
                            <div className='dp-inner'>
                                <img src={Img.Plsnews} />
                                <div className='custom-heading'>
                                    Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                                <div className='custom-text ct-small'>
                                    With India being home to over 104 million entrepreneurs, the scope for innovation and creation is stronger than ever. Keeping this ever-expanding entrepreneurial pool in mind, Entrepreneur India brings Entrepreneur Summit 2024 taking things to the next level
                                </div>
                            </div>
                            <Pagination />

                            {/*Next preview */}
                            <div className='detail-next-preview'>
                                <div
                                    className='detail-preview'
                                    onClick={togglePreviewDropdown}
                                    onMouseEnter={handlePreviewMouseEnter}
                                    onMouseLeave={handlePreviewMouseLeave}
                                >
                                    <div className='dnp-title'>
                                        <span><Icons.ChevronleftSvg /></span> Preview
                                        <div class="custom-text ct-small">JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                    </div>
                                    
                                    <div className={`dropdown-menu ${isPreviewDropdownOpen ? 'open' : ''}`}>
                                        <ul>
                                            <li><Link>Jamia revokes suspension of professor accused of sexual</Link></li>
                                            <li><Link>Australian man charged with several offences over fatal crash that</Link></li>
                                            <li><Link>Paris 2024 Olympics: Imane Khelif assured of a medal, calls</Link></li>
                                        </ul>
                                    </div>
                                </div>  

                                <div
                                    className='detail-Next text-end'
                                    onClick={toggleNextDropdown}
                                    onMouseEnter={handleNextMouseEnter}
                                    onMouseLeave={handleNextMouseLeave}
                                >
                                    <div className='dnp-title'>
                                        Next <span><Icons.Chevronright /></span>
                                        <div class="custom-text ct-small">JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                    </div>
                                    <div className={`dropdown-menu ${isNextDropdownOpen ? 'open' : ''}`}>
                                        <ul>
                                            <li><Link>JU.S. F-16 jet crashes in South Korea, pilot rescued</Link></li>
                                            <li><Link>U.S. calls on Beijing to stop 'dangerous' conduct in South China Sea</Link></li>
                                            <li><Link>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-5 col-lg-5 col-xl-4'>
                            <div className='common-news-box row-gap'>
                                <div className='flex-box'>
                                    <div className='custom-heading ch-medium'>
                                        Recent Posts
                                    </div>
                                </div>
                                <div className='cnb-box'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='cnb-img'>
                                                <img src={Img.P1} />
                                            </div>
                                            <div className='cnb-content'>
                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                <div className='custom-text'>12 hours ago</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='cnb-box'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='cnb-img'>
                                                <img src={Img.P2} />
                                            </div>
                                            <div className='cnb-content'>
                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                <div className='custom-text'>12 hours ago</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='cnb-box'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='cnb-img'>
                                                <img src={Img.P3} />
                                            </div>
                                            <div className='cnb-content'>
                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                <div className='custom-text'>12 hours ago</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>

                                <div className='cnb-box'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='cnb-img'>
                                                <img src={Img.P3} />
                                            </div>
                                            <div className='cnb-content'>
                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                <div className='custom-text'>12 hours ago</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                                {/*Quick link start*/}
                                <div className="quick-links-section">

                                    <div className="quick-links-inner">
                                        <div className="custom-heading ch-medium">
                                            Quick Links
                                        </div>
                                        <div className="quick-links-buttons">
                                            {categories.map(category => (
                                                <Link
                                                    key={category.id}
                                                    className={`btn quick-link-btn common-btn-item cbi-outline`}
                                                >
                                                    {category.name}
                                                </Link>
                                            ))}
                                        </div>
                                    </div>

                                </div>
                                {/*Quick link End*/}
                            </div>



                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default Detail