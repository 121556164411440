import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../../routers/Sidebar';

// Icons & imgeas common files
import * as Img from '../../components/Img';

// Css File
import "../../css/header.css"
import Icons from '../../components/icons';


const Header = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    handleResize(); // Check on mount
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  //searchbar
  const handleSearchClick = () => {
    if (isMobile) {
      setIsSearchOpen(!isSearchOpen);
    }
  };


  //sidebar
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const closeSidebar = () => {
        setIsSidebarOpen(false);
    };

    useEffect(() => {
        if (isSidebarOpen) {
            // document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }

        return () => {
            document.body.style.overflow = '';
        };
    }, [isSidebarOpen]);

  return (
    <header className='main-header'>
      <div className='mh-inner'>
        <div className='mh-left'>
        <div className={`app-container ${isSidebarOpen ? 'overlay' : ''}`}>
            {isSidebarOpen && <div className="overlay-background" onClick={closeSidebar}></div>}
            <div className='menubox-icon' onClick={toggleSidebar}>
                <span></span>
                <span></span>
                <span></span>
            </div>
            <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
            {/* Main content goes here */}
        </div>
        </div>
        <div className='mh-center'>
          <Link to="/"><img src={Img.logo} alt="Logo" /></Link>
        </div>
        <div className='mh-right'>
          <div className='mhr-inner'>
            <div className="mh-search-bar">
              <div className="icon" onClick={handleSearchClick}>
                <Icons.MagnifyingglassSvg />
              </div>
              {!isMobile && (
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search for news"
                />
              )}
            </div>
            <div className='common-btn d-none'>Sign in</div>
          </div>
        </div>
      </div>

      {isMobile && (
        <div className={`mobile-search-popup mh-search-bar ${isSearchOpen ? 'open' : ''}`}>
          <div className="icon" onClick={handleSearchClick}>
            <Icons.MagnifyingglassSvg />
          </div>
          <input
            type="text"
            className="search-input"
            placeholder="Search for news"
          />
        </div>
      )}
    </header>
  );
};

export default Header