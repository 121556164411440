import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//Header section
import ScrollToTop from "./ScrollToTop";
import Loader from "./Loader";
import Header from "../containers/Header/Header";
// import Home from "../containers/Pagehome/Home";
import Footer from "../containers/Footer/Footer";
// import Blogdetail from "../containers/Pageblogdetail/Blogdetail";



// // Pages
import Home from "../containers/Pagehome/Home";
import Detail from "../containers/Pagedetail/Detail";

export const pages = [

  { path: "/", component: Home },

  { path: "/Detail", component: Detail },

];

const MyRoutes = () => {

  return (
    <>
        <ScrollToTop />
        <Loader />
        <Header />
        <Routes>
          {pages.map(({ component: Component, path }, index) => {
            return <Route key={index} element={<Component />} path={path} />;
          })}
        </Routes>
        <Footer />
    </>
  );
};

function MainRoutes() {
  return (
    <Router>
        <MyRoutes />
    </Router>
  );
}

export default MainRoutes;