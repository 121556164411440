import React, { useState } from 'react';
import { Link } from 'react-router-dom';

// Icons & imgeas common files
import * as Img from '../components/Img';
import Icons from '../components/icons';

//sidebar css
import '../css/sidebar.css'

const Sidebar = ({ isOpen, toggleSidebar }) => {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    return (
        <div className='main-sidebar'>
            <div className={`sidebar ${isOpen ? 'open' : ''}`}>
                <div className="sidebar-header">
                    <div className="logo"><Link><img src={Img.logo} alt="Logo" /></Link></div>
                    <button className="close-btn" onClick={toggleSidebar}><Icons.XmarkSvg /></button>
                </div>
                <ul className="sidebar-menu">
                    <li><Link to="/">Home</Link></li>
                    <li><Link>About Us</Link></li>
                    <li>
                        <Link onClick={toggleDropdown}>
                            Category {isDropdownOpen ? <Icons.ChevronupSvg/> : <Icons.ChevrondownSvg/>}
                        </Link>
                        {isDropdownOpen && (
                            <ul className="dropdown-menu">
                                <li><Link>Sports</Link></li>
                                <li><Link>Technology</Link></li>
                                <li><Link>Entertainment</Link></li>
                            </ul>
                        )}
                    </li>
                    <li><Link>Post</Link></li>
                    <li><Link>Contact Us</Link></li>
                </ul>
            </div>
        </div>
    );
};

export default Sidebar;
