import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';


const newsItems = [
    {
        id: 1,
        image: 'Brkslider',
        heading: 'Protest against war crimes erupts in Italy as thousands gather near Bell Tower',
        text: 'Italy has called on the European Union to impose ad hoc sanctions against Hamas and its supporters, the foreign ministers of the three nations wrote in a joint letter to the EU s foreign policy chief Josep Borrell. Follow our live blog for the latest developments on the Israel-Hamas war. All times are Paris time (GMT+1).'
    },
    {
        id: 2,
        image: 'Brkslider',
        heading: 'Protest against war crimes erupts in Italy as thousands gather near Bell Tower',
        text: 'Italy has called on the European Union to impose ad hoc sanctions against Hamas and its supporters, the foreign ministers of the three nations wrote in a joint letter to the EU s foreign policy chief Josep Borrell. Follow our live blog for the latest developments on the Israel-Hamas war. All times are Paris time (GMT+1).'
    },
    // Add more items as needed
];




const Breakingnews = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const CustomPrevArrow = ({ onClick, Icons }) => (
        <div className="custom-arrow custom-prev" onClick={onClick}>
            <Icons.ChevronleftSvg />
        </div>
    );

    const CustomNextArrow = ({ onClick, Icons }) => (
        <div className="custom-arrow custom-next" onClick={onClick}>
            <Icons.Chevronright />
        </div>
    );
    const settings = {
        dots: false,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        beforeChange: (current, next) => setActiveIndex(next),
        prevArrow: <CustomPrevArrow onClick={() => setActiveIndex(activeIndex - 1)} Icons={Icons} />,
        nextArrow: <CustomNextArrow onClick={() => setActiveIndex(activeIndex + 1)} Icons={Icons} />,
        responsive: [
            {
                breakpoint: 767, // Tablet and mobile
                settings: {
                    arrows: false,
                },
            },
        ],
    };

    return (
        <>

            {/*Breaking New start*/}
            <div className='breakingnews-section padding-50'>
                <div className='container-lg container-custom'>
                    <div className='brk-inner'>
                        <div className='row row-gap-4'>
                            <div className='col-md-8 col-lg-8 col-xl-9'>
                                <div className='brk-left row-gap'>
                                    <div className='custom-heading ch-medium'>
                                        <span className='icon'><Icons.ClockSvg /></span>Breaking news
                                    </div>
                                    <div className='brk-slider'>
                                        <Slider {...settings}>
                                            {newsItems.map((item) => (
                                                <Link>
                                                    <div key={item.id} className="news-card">
                                                        <img src={Img.Brkslider} alt={item.heading} className="news-image" />
                                                        <div className="news-content">
                                                            <div className="custom-heading">{item.heading}</div>
                                                            <div className="custom-text">{item.text}</div>
                                                        </div>
                                                        {/*Social activity*/}
                                                        <div className="activity">
                                                            <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                            <span className="comment">
                                                                <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                                    <Icons.DocumentduplicateSvg />
                                                                </Link>
                                                            </span>
                                                            <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                        </div>
                                                        {/*Social activity*/}
                                                    </div>


                                                </Link>
                                            ))}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 col-lg-4 col-xl-3'>
                                <div className='brk-right row-gap'>
                                    <div className='brk-trading flex-box'>
                                        <Link className='custom-heading ch-medium'>
                                            <span className='icon'><Icons.ArrowtrendingupSvg /></span>Trending
                                        </Link>

                                        <Link className='custom-heading ch-small' >
                                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                                        </Link>
                                    </div>

                                    <div className='brklist-trading'>
                                        <div className='brklt-inner row-gap'>
                                            <Link >
                                                <div className='brklt-box'>
                                                    <div className='brkit-img'>
                                                        <img src={Img.Tradingone} />
                                                    </div>

                                                    <div className='brklt-content'>
                                                        <div className='custom-heading ch-medium'>Jamia revokes suspension of professor accused of sexual.....</div>
                                                        <div className='custom-text'>3 mins ago</div>
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link >
                                                <div className='brklt-box'>
                                                    <div className='brkit-img'>
                                                        <img src={Img.Newstwo} />
                                                    </div>
                                                    <div className='brklt-content'>
                                                        <div className='custom-heading ch-medium'>Australian man charged with several offences over fatal crash that ........</div>
                                                        <div className='custom-text'>3 hours ago</div>
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link >
                                                <div className='brklt-box'>
                                                    <div className='brkit-img'>
                                                        <img src={Img.Newsthree} />
                                                    </div>
                                                    <div className='brklt-content'>
                                                        <div className='custom-heading ch-medium'>Tibetans in exile accuse China of destroying their identity in .........</div>
                                                        <div className='custom-text'>6 hours ago</div>
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link >
                                                <div className='brklt-box'>
                                                    <div className='brkit-img'>
                                                        <img src={Img.Newsfive} />
                                                    </div>
                                                    <div className='brklt-content'>
                                                        <div className='custom-heading ch-medium'>U.S. calls on Beijing to stop 'dangerous' conduct in South China Sea</div>
                                                        <div className='custom-text'>9 hours ago</div>
                                                    </div>
                                                </div>
                                            </Link>

                                            <Link>
                                                <div className='brklt-box'>
                                                    <div className='brkit-img'>
                                                        <img src={Img.Newsfour} />
                                                    </div>
                                                    <div className='brklt-content'>
                                                        <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                        <div className='custom-text'>12 hours ago</div>
                                                    </div>
                                                </div>
                                            </Link>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*Breaking New End*/}
        </>
    )
}

export default Breakingnews