import React from 'react'
import { Link } from 'react-router-dom';

// Icons & imgeas common files
import Icons from '../components/icons';

const Pagination = () => {
    return (
        <>
            {/*pagination*/}
            <div class="pagination-section padding-top-50">
                <nav className="pagination-outer">
                    <ul className="pagination">
                        <li className="page-item">
                            <Link className="page-link" aria-label="Previous">
                                <span aria-hidden="true"><Icons.ChevronleftSvg /></span>
                            </Link>
                        </li>
                        <li className="page-item active"><Link className="page-link">1</Link></li>
                        <li className="page-item"><Link className="page-link">2</Link></li>
                        <li className="page-item "><Link className="page-link">3</Link></li>
                        <li className="page-item">
                            <Link  className="page-link" aria-label="Next">
                                <span aria-hidden="true"><Icons.Chevronright /></span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}

export default Pagination