import React from 'react'
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Marketnews = () => {
    return (
        <>
            {/*market section start*/}
            <div className='market-section padding-50'>
                <div className='container-lg'>
                    <div className='mrs-inner'>
                        <div className='flex-box'>
                            <div className='custom-heading ch-medium'>
                                Market
                            </div>
                        </div>
                    </div>

                    <div className='mrs-detail'>
                        <div className='row row-gap-4'>
                            <div className='col-md-8 border-right sp-e-4'>
                                <div className='mrs-img'>
                                    <Link>
                                        <div className='mrs-img-inner'>
                                            <img src={Img.Market1} />
                                        </div>

                                        <div className='custom-heading'>Latest Market News Today Live Updates August 9, 2024: </div>
                                    </Link>
                                </div>
                                <div className='row row-gap-4'>
                                    <div className='col-md-6'>
                                        <div className='common-news-box'>
                                            <div className='cnb-box'>
                                                <Link>
                                                    <div className='cnb-inner'>
                                                        <div className='cnb-img'>
                                                            <img src={Img.Market4} />
                                                        </div>
                                                        <div className='cnb-content'>
                                                            <div className='custom-heading ch-medium'>How To Make Money Betting Against Nature’s Wrath...</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='common-news-box'>
                                            <div className='cnb-box'>
                                                <Link>
                                                    <div className='cnb-inner'>
                                                        <div className='cnb-img'>
                                                            <img src={Img.Market5} />
                                                        </div>
                                                        <div className='cnb-content'>
                                                            <div className='custom-heading ch-medium'>Latest Market News Today Live Updates: Catch today's market ...</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='common-news-box'>
                                            <div className='cnb-box'>
                                                <Link>
                                                    <div className='cnb-inner'>
                                                        <div className='cnb-img'>
                                                            <img src={Img.Market6} />
                                                        </div>
                                                        <div className='cnb-content'>
                                                            <div className='custom-heading ch-medium'>Tax Breaks: Our Olympic-Sized Tax Edition Look...</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <div className='common-news-box'>
                                            <div className='cnb-box'>
                                                <Link>
                                                    <div className='cnb-inner'>
                                                        <div className='cnb-img'>
                                                            <img src={Img.Market7} />
                                                        </div>
                                                        <div className='cnb-content'>
                                                            <div className='custom-heading ch-medium'>Student loan visa for Abrode
                                                                for Further study...</div>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-4 sp-s-4'>
                                <div className='common-news-box row-gap upcoming-news'>
                                    <div className='cnb-box'>
                                        <Link>
                                            <div className='cnb-inner'>
                                                <div className='cnb-img'>
                                                    <img src={Img.Market2} />
                                                </div>
                                                <div className='cnb-content'>
                                                    <div className='custom-heading ch-medium'>Coca-Cola Could Face $16 Billion IRS Bill After Its Tax Court Arguments Fall Flat...</div>
                                                    <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                    <div className='custom-text'>12 hours ago</div>
                                                </div>
                                                {/*Social activity*/}
                                                <div className="activity">
                                                    <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                    <span className="comment">
                                                        <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                            <Icons.DocumentduplicateSvg />
                                                        </Link>
                                                    </span>
                                                    <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                </div>
                                                {/*Social activity*/}
                                            </div>
                                        </Link>

                                    </div>
                                    <div className='cnb-box'>
                                        <Link>
                                            <div className='cnb-inner'>
                                                <div className='cnb-img'>
                                                    <img src={Img.Market3} />
                                                </div>
                                                <div className='cnb-content'>
                                                    <div className='custom-heading ch-medium'>Market Lessons: What Free Cash Flow Tells You—With 16 Cheap Stocks Market...</div>
                                                    <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                    <div className='custom-text'>12 hours ago</div>
                                                </div>
                                                {/*Social activity*/}
                                                <div className="activity">
                                                    <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                    <span className="comment">
                                                        <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                            <Icons.DocumentduplicateSvg />
                                                        </Link>
                                                    </span>
                                                    <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                </div>
                                                {/*Social activity*/}
                                            </div>
                                        </Link>

                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>

                    <div className='common-advertisement d-none d-md-block margin-50 mb-0'>
                        <button className='close-btn'>
                            <Icons.XmarkSvg />
                        </button>
                        <Link className='adt-inner'>
                            <img src={Img.Advertisetwo} />
                        </Link>
                    </div>
                </div>
            </div>
            {/*market section end*/}
        </>
    )
}

export default Marketnews