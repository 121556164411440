import React from 'react';

//components
import Commoninformation from './Commoninformation';
import Breakingnews from './Breakingnews';
import Politicsnews from './Politicsnews';
import Cardslider from './Cardslider';
import Luxuryproducts from './Luxuryproducts';
import Sportnews from './Sportnews';
import MonthStorySlider from './MonthStorySlider';
import Topstoryslider from './Topstoryslider';
import Marketnews from './Marketnews';




const Home = () => {

    return (
        <>
            <Commoninformation />
            <Breakingnews />
            <Politicsnews />
            <Cardslider />
            <Luxuryproducts />
            <Sportnews />
            <MonthStorySlider />
            <Topstoryslider />
            <Marketnews />

        </>
    )
}

export default Home