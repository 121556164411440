import React, { useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';


const Topstoryslider = () => {
    const settings = {
        dots: false,
        infinite: true,
        speed: 1500,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return (
        <>
            {/*Top Story start*/}
            <div className="top-story-section padding-50">
                <div className='container-fluid'>
                    <div className="custom-heading">Top Stories</div>
                    <div className="custom-subheading ch-small">Latest Updates</div>

                    <div className="tps-inner">
                        <Slider {...settings}>
                            <div className="common-news-box">
                                <div className='cnb-box'>
                                    <Link>
                                        <div className="cnb-inner">
                                            <div className="cnb-img">
                                                <img src={Img.Sportthree} />
                                            </div>
                                            <div className="cnb-content">
                                                <div className="custom-heading">
                                                    Vinesh Phogat retires a day after disqualification from Paris Olympics:
                                                </div>
                                                <div className="custom-text">
                                                    Wrestling Federation of India (WFI) chief Sanjay Singh has urged Vinesh Phogat to not...
                                                </div>
                                                <div className="custom-text"> Aug 09, 2024 02:41 IST</div>
                                                {/*Social activity*/}
                                                <div className="activity">
                                                    <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                    <span className="comment">
                                                        <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                            <Icons.DocumentduplicateSvg />
                                                        </Link>
                                                    </span>
                                                    <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                </div>
                                                {/*Social activity*/}
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            </div>
                            <div className="common-news-box">
                                <div className='cnb-box'>
                                    <Link>
                                        <div className="cnb-inner">
                                            <div className="cnb-img">
                                                <img src={Img.Sportfour} />
                                            </div>
                                            <div className="cnb-content">
                                                <div className="custom-heading ">
                                                    Paris 2024 Olympics: Imane Khelif assured of a medal, calls...
                                                </div>
                                                <div className="custom-text">
                                                    The 2024 Paris Olympics are nearly over, but Friday has plenty of action to follow in ...
                                                </div>
                                                <div className="custom-text"> Aug 09, 2024 02:41 IST</div>
                                                {/*Social activity*/}
                                                <div className="activity">
                                                    <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                    <span className="comment">
                                                        <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                            <Icons.DocumentduplicateSvg />
                                                        </Link>
                                                    </span>
                                                    <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                </div>
                                                {/*Social activity*/}
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                            </div>
                            {/* Repeat similar blocks for more stories */}
                        </Slider>
                    </div>
                </div>
            </div>
            {/*Top Story End*/}
        </>
    );
};

export default Topstoryslider;