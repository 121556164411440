import React from 'react'
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Sportnews = () => {
    return (
        <>
            {/*Sport Section start*/}
            <div className='sport-news-section padding-50'>
                <div className='container-lg row-gap'>
                    <div className='flex-box'>
                        <div className='custom-heading ch-medium'>
                            Sports
                        </div>
                        <Link className='custom-heading ch-small'>
                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                        </Link>
                    </div>
                    <div className='spns-inner'>
                        <Link>
                            <img src={Img.Sport} />
                            <div class="custom-heading">The Men’s Selection Committee on Thursday announced India’s squads for the upcoming tour of Sri Lanka.</div>
                        </Link>
                    </div>

                    <div className='row row-gap-4'>
                        <div className='col-md-7 col-lg-8'>
                            <div className='common-news-box row-gap'>
                                <div className='cnb-box latest-news'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='row row-gap-4'>
                                                <div className='col-md-7'>
                                                    <div className='cnb-img'>
                                                        <img src={Img.Sporttwo} />
                                                    </div>
                                                </div>
                                                <div className='col-md-5'>
                                                    <div className='cnb-content'>
                                                        <div className='custom-heading'>Neeraj Chopra Highlights, Javelin Throw Final, Paris 2024 Olympics:</div>
                                                        <div className='custom-text'>All done for the day. The elusive gold remains elusive for India as we head into August 9. That Neeraj’s silver hurts to this extent, shows the levels he has taken the sport to. </div>
                                                        <div className='custom-text'> Aug 09, 2024 02:41 IST</div>
                                                    </div>

                                                    {/*Social activity*/}
                                                    <div className="activity">
                                                        <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                        <span className="comment">
                                                            <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                                <Icons.DocumentduplicateSvg />
                                                            </Link>
                                                        </span>
                                                        <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                    </div>
                                                    {/*Social activity*/}
                                                </div>
                                            </div>


                                        </div>
                                    </Link>
                                </div>
                            </div>
                        </div>

                        <div className='col-md-5 col-lg-4'>
                            <div className='common-news-box row-gap'>
                                <div className='cnb-box'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='cnb-img'>
                                                <img src={Img.Sportthree} />
                                            </div>
                                            <div className='cnb-content'>
                                                <div className='custom-heading ch-medium'>Vinesh Phogat retires a day after disqualification from Paris Olympics:</div>
                                                <div className='custom-text'>Wrestling Federation of India (WFI) chief Sanjay Singh has urged  Vinesh Phogat to not...</div>
                                                <div className='custom-text'> Aug 09, 2024 02:41 IST</div>
                                            </div>

                                        </div>
                                    </Link>
                                </div>
                                <div className='cnb-box'>
                                    <Link>
                                        <div className='cnb-inner'>
                                            <div className='cnb-img'>
                                                <img src={Img.Sportfour} />
                                            </div>
                                            <div className='cnb-content'>
                                                <div className='custom-heading ch-medium'>Paris 2024 Olympics: Imane Khelif assured of a medal, calls...</div>
                                                <div className='custom-text'>The 2024 Paris Olympics are nearly over, but Friday has plenty of action to follow in ...</div>
                                                <div className='custom-text'> Aug 09, 2024 02:41 IST</div>
                                            </div>
                                        </div>
                                    </Link>
                                </div>
                            </div>


                        </div>

                    </div>
                </div>
            </div>
            {/*Sport Section end*/}
        </>
    )
}

export default Sportnews