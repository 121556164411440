import React, { useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';

// Icons & imgeas common files
import * as Img from '../../components/Img';



const MonthStorySlider = () => {
    const [activeIndex, setActiveIndex] = useState(0);

    const slides = [
        {
            image: Img.Monthlynews,
            text: "Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’. Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’. Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’. Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’. Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.  ’.",
        },
        {
            image: Img.Monthlynews,
            text: "Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’. Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’.",
        },
        {
            image: Img.Monthlynews,
            text: "Tiger Woods’ comeback at the Hero World Challenge proves he’s not ready to ‘sail off into the sunset’. Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza."
        }
        // Add more slides as needed
    ];

    const settings = {
        vertical: true,
        verticalSwiping: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        speed: 1500,
        beforeChange: (current, next) => setActiveIndex(next),
        dots: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 767, // Tablet and mobile
                settings: {
                    vertical: true,
                    verticalSwiping: true,
                    dots: false,
                },
            },
        ],
    };

    return (
        <>
            {/*mothof story start*/}
            <div className='month-story margin-50'>
                <div className='container-lg'>
                    <Link>
                        <div className='ms-inner'>
                            <div className='row row-gap-3'>
                                <div className='col-md-4'>
                                    <div className='custom-heading'>THIS MONTH STORY <span className='red'>JUN</span>/24</div>
                                </div>
                                <div className='col-md-4'>
                                    <Slider {...settings}>
                                        {slides.map((slide, index) => (
                                            <div key={index} className='ms-img'>
                                                <img src={slide.image} alt={`Slide ${index}`} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                <div className='col-md-4'>
                                    <div className='custom-text'>
                                        {slides[activeIndex].text}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Link>
                </div>
            </div>
            {/*mothof story end*/}
        </>
    );
};

export default MonthStorySlider;
