import React from 'react'
import { Link } from 'react-router-dom';
// Masonry img section
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Luxuryproducts = () => {
  return (
    <>
    {/*Luxury products start*/}
    <div className='luxury-products-section padding-50'>
                <div className='container-lg'>
                    <div className='lps-inner row-gap'>
                        <div className='flex-box'>
                            <div className='custom-heading ch-medium'>
                                Luxury products
                            </div>
                            <Link className='custom-heading ch-small'>
                                View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                            </Link>
                        </div>

                        <ResponsiveMasonry
                            columnsCountBreakPoints={{ 350: 1, 400: 2, 750: 2, 900: 3 }}
                        >
                            <Masonry columnsCount={3} gutter="15px">
                                <div className="masonry-item">
                                    <Link>
                                        <img src={Img.M1} />
                                    </Link>
                                </div>
                                <div className="masonry-item">
                                    <Link>
                                        <img src={Img.M2} />
                                    </Link>
                                </div>
                                <div className="masonry-item">
                                    <Link>
                                        <img src={Img.M3} />
                                    </Link>
                                </div>
                                <div className="masonry-item">
                                    <Link>
                                        <img src={Img.M4} />
                                    </Link>
                                </div>
                                <div className="masonry-item">
                                    <Link >
                                        <img src={Img.M5} />
                                    </Link>
                                </div>
                                <div className="masonry-item">
                                    <Link>
                                        <img src={Img.M6} />
                                    </Link>
                                </div>
                            </Masonry>
                        </ResponsiveMasonry>
                    </div>

                    <div className='common-advertisement margin-50 mb-0 d-none d-md-block'>
                        <button className='close-btn'>
                            <Icons.XmarkSvg />
                        </button>
                        <Link className='adt-inner'>
                            <img src={Img.Advertisetwo} />
                        </Link>
                    </div>
                </div>
            </div>
            {/*Luxury products End*/}
    </>
  )
}

export default Luxuryproducts