import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';


const Politicsnews = () => {

    const [isAdVisible, setIsAdVisible] = useState(true);

    const handleAdClose = () => {
        setIsAdVisible(false);
    };

    return (
        <>
            {/*Politics New start*/}
            <div className='politicsnew-section padding-50'>
                <div className='container-lg container-custom'>
                    <div className='pls-news'>
                        <div className='pls-left'>
                            <div className='row row-gap-4'>
                                <div className={`col-${isAdVisible ? 'md-9' : 'md-12'} row-gap`}>
                                    <div className='flex-box'>
                                        <div className='custom-heading ch-medium'>
                                            Politics
                                        </div>
                                        <Link className='custom-heading ch-small'>
                                            View all <span className='icon'><Icons.ArrowlongrightSvg /></span>
                                        </Link>
                                    </div>
                                    <div className='row row-gap-4'>
                                        <div className='col-md-6'>
                                            <Link to="/Detail" className='pls-inner'>
                                                <div className='zoom-img'>
                                                    <img src={Img.Plsnews} />
                                                </div>
                                                <div className='custom-heading'>
                                                    Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza
                                                </div>
                                                {/*Social activity*/}
                                                <div className="activity">
                                                    <span className="views"><Link><Icons.ShareSvg /> 12</Link></span>
                                                    <span className="comment">
                                                        <Link data-tooltip-id='duplicateTooltip' data-tooltip-content='Copy Link'>
                                                            <Icons.DocumentduplicateSvg />
                                                        </Link>
                                                    </span>
                                                    <Tooltip id='duplicateTooltip' place='top' effect='solid' />
                                                </div>
                                                {/*Social activity*/}
                                            </Link>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='common-news-box row-gap'>
                                                <div className='cnb-box'>
                                                    <Link>
                                                        <div className='cnb-inner'>
                                                            <div className='cnb-img'>
                                                                <img src={Img.P1} />
                                                            </div>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                                <div className='custom-text'>12 hours ago</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className='cnb-box'>
                                                    <Link>
                                                        <div className='cnb-inner'>
                                                            <div className='cnb-img'>
                                                                <img src={Img.P2} />
                                                            </div>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                                <div className='custom-text'>12 hours ago</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>

                                                <div className='cnb-box'>
                                                    <Link>
                                                        <div className='cnb-inner'>
                                                            <div className='cnb-img'>
                                                                <img src={Img.P3} />
                                                            </div>
                                                            <div className='cnb-content'>
                                                                <div className='custom-heading ch-medium'>JU.S. F-16 jet crashes in South Korea, pilot rescued after ejecting</div>
                                                                <div className='custom-text'>Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza.Egypt’s president expected to secure third term as the world’s eyes are fixed on Gaza</div>
                                                                <div className='custom-text'>12 hours ago</div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isAdVisible && (
                                    <div className='col-md-3'>
                                        <div className='adver-tisement d-none d-md-block'>
                                            <button className='close-btn' onClick={handleAdClose}>
                                                <Icons.XmarkSvg />
                                            </button>
                                            <Link className='adt-inner'>
                                                <img src={Img.Advertise} />
                                            </Link>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
            {/*Politics New End*/}
        </>
    )
}

export default Politicsnews