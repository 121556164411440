import React from 'react'
import { Link } from 'react-router-dom';

// Css File
import '../../css/footer.css'

// Icons & imgeas common files
import * as Img from '../../components/Img';
import Icons from '../../components/icons';

const Footer = () => {
    return (
        <div className='footer-section padding-50'>
            <div className='container-lg'>
                <div className='footer-section-inner'>
                    <div className='footer-top'>
                        <div className='footer-left'>
                            <div className='custom-heading ch-medium'>Sign up for newsletter</div>
                            <div className='custom-text ct-small '>Sign up now and be the first to know about exclusive offers, latest fashion news & style tips!</div>
                        </div>
                        <div className='footer-right'>
                            <ul>
                                <li><Link><img src={Img.Twitter} alt='twitter' /></Link></li>
                                <li><Link><img src={Img.Instagram} alt='instagram' /></Link></li>
                                <li><Link><img src={Img.YouTube} alt='youtube' /></Link></li>
                                <li><Link><img src={Img.LinkedIn} alt='linkdin' /></Link></li>
                            </ul>
                        </div>
                    </div>

                    <div className='footer-center'>
                        {/* <label >Email</label> */}
                        <div className='custom-input'>
                            <input type="email" id="email" name="email" className='form-control' placeholder='Enter your Email' />
                            <button className='common-btn-item cbi-solid'><Icons.EnvelopeSvg /> Subscribe</button>
                        </div>
                    </div> 

                    <div className='footer-bottom'>
                        <ul>
                            <li><Link>Home</Link><span>|</span></li>
                            <li><Link>About us</Link><span>|</span></li>
                            <li><Link>Category</Link> <span>|</span></li>
                            <li><Link>Contact Us</Link><span>|</span></li>
                            <li><Link>Privacy Policy</Link><span>|</span></li>
                            <li><Link>Term & Condatin</Link></li>
                        </ul>

                        <div className='footer-copyright'>
                            <div className='custom-heading ch-small'>© 2024,  Forbes  Media LLC. All Rights Reserved</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer